export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage?: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  facebook?: string;
  itchio?: string;
  /**
   * full url, no username
   */
  twitter?: string;
  instagram?: string;
  youtube?: string;
  linkedin?: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;
  /**
  /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
}

const config: WebsiteConfig = {
  title: 'kazzuyak',
  description: "kazzuyak's website",
  coverImage: 'img/blog-cover.png',
  logo: 'img/ghost-logo.png',
  lang: 'en',
  siteUrl: 'https://kazzuyak.com',
  facebook: 'https://www.facebook.com/people/kazzuyak/100070267615688/',
  twitter: 'https://twitter.com/kazzuyak_',
  instagram: 'https://www.instagram.com/kazzuyak/',
  youtube: 'https://www.youtube.com/kazzuyak',
  linkedin: 'https://www.linkedin.com/in/kazzuyak/',
  itchio: 'https://kazzuyak.itch.io/',
  showSubscribe: false,
  mailchimpAction: '',
  mailchimpName: '',
  mailchimpEmailFieldName: 'MERGE0',
  googleSiteVerification: 'GoogleCode',
  footer: '',
};

export default config;
